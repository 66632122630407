import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px; /* Add some padding for smaller screens */
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: ${({ isDesktop }) => (isDesktop ? '15px 30px' : '12px 24px')};
  border-radius: 5px;
  cursor: pointer;
  font-size: ${({ isDesktop }) => (isDesktop ? '20px' : '16px')}; 
  margin-top: 0px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Heading = styled.h1`
  font-size: ${({ isDesktop }) => (isDesktop ? '48px' : '36px')}; 
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')}; 
  margin-bottom: 40px;
`;

const NotFound = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:768px)');

  return (
    <Container>
      <Heading isDesktop={isDesktop}>404 - Oops!</Heading>
      <Paragraph isDesktop={isDesktop}>We can't find the page you're looking for.</Paragraph>
      <Button isDesktop={isDesktop} onClick={() => navigate('/')}>
        Back to Home
      </Button>
    </Container>
  );
};

export default NotFound;
