import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login'; // Import the Login icon
import AccountCircleIcon from '@mui/icons-material/AccountCircleRounded';
import { useMediaQuery } from '@mui/material';
import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from '../firebase';
import { useNavigate } from 'react-router-dom';

// Sidebar component
const Sidebar = styled.div`
  width: ${(props) =>
    props.isDesktop
      ? props.expanded
        ? '250px'
        : '70px'
      : props.expanded
      ? '150px'
      : '70px'}; /* Toggle between 70px and 150px on mobile, 250px on desktop */
  height: 100vh;
  background-color: #ccd4fd;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.expanded ? 'flex-start' : 'center')}; /* Align left when expanded */
  padding-top: 20px;
  box-sizing: border-box;
  transition: width 0.3s ease; /* Smooth transition for expanding */
  overflow: hidden;
`;

const SidebarItem = styled.div`
  color: #2c3e50;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Align items to center to ensure icon and text are aligned */
  width: 100%;
  white-space: nowrap; /* Prevent text wrapping */
  &:hover {
    background-color: #8b9bff;
  }
`;

const Icon = styled.span`
  margin-right: ${(props) => (props.expanded ? '10px' : '0')}; /* Add margin when expanded */
  margin-left: ${(props) => (props.expanded ? '0' : '20px')}; /* Add margin when collapsed */
  display: inline-flex; /* Ensure inline-flex to keep the icon inline with the text */
  align-items: center; /* Center align the icon within the flex box */
  justify-content: center;
`;

const Label = styled.span`
  display: ${(props) => (props.expanded ? 'inline' : 'none')}; /* Hide label when collapsed */
  vertical-align: middle; /* Align text vertically in the middle */
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding-left: ${(props) => (props.expanded ? '20px' : '0')}; /* Add padding when expanded */
  width: 100%;
  justify-content: ${(props) => (props.expanded ? 'flex-start' : 'center')};
`;

const ProfilePicture = styled.div`
  width: ${(props) => (props.expanded ? '50px' : '40px')};
  height: ${(props) => (props.expanded ? '50px' : '40px')};
  border-radius: 50%;
  background-color: #34495e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.expanded ? '10px' : '0')};

  &:hover {
    width: ${(props) => (props.expanded ? '50px' : '40px')};
    height: ${(props) => (props.expanded ? '50px' : '40px')};
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: none;
    &:hover {
      transform: none;
    }
  }
`;

const ProfileName = styled.span`
  display: ${(props) => (props.expanded ? 'inline' : 'none')}; /* Hide name when collapsed */
`;

// Main content area
const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentBox = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  height: 200px;
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnauthorizedMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: red;
`;

const Dashboard = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const [user, setUser] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const navigate = useNavigate();

  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setPhotoURL(user.photoURL);
        setProfileName(user.displayName);
      } else {
        setPhotoURL(null);
        setProfileName('Not Signed In');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User logged out');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/dashboard');
      }).catch((error) => {
        console.error('Error logging in:', error);
      });
  };

  return (
    <DashboardContainer>
      <Sidebar
        isDesktop={isDesktop}
        expanded={isExpanded}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <SidebarItem onClick={() => navigate('/dashboard')}>
          <Icon expanded={isExpanded}>
            <DashboardIcon />
          </Icon>
          <Label expanded={isExpanded}>Dashboard</Label>
        </SidebarItem>
        <SidebarItem onClick={() => navigate('/profile')}>
          <Icon expanded={isExpanded}>
            <PersonIcon />
          </Icon>
          <Label expanded={isExpanded}>Profile</Label>
        </SidebarItem>
        <SidebarItem onClick={() => navigate('/dashboard/plans')}>
          <Icon expanded={isExpanded}>
            <SettingsIcon />
          </Icon>
          <Label expanded={isExpanded}>Plannr Pro</Label>
        </SidebarItem>

        {/* Conditionally render Logout or Login based on user authentication */}
        {user ? (
          <SidebarItem onClick={handleLogout}>
            <Icon expanded={isExpanded}>
              <LogoutIcon />
            </Icon>
            <Label expanded={isExpanded}>Sign Out</Label>
          </SidebarItem>
        ) : (
          <SidebarItem onClick={handleGoogleSignIn}>
            <Icon expanded={isExpanded}>
              <LoginIcon />
            </Icon>
            <Label expanded={isExpanded}>Sign In</Label>
          </SidebarItem>
        )}

        <ProfileContainer expanded={isExpanded}>
          <ProfilePicture expanded={isExpanded}>
            {photoURL ? (
              <img src={photoURL} alt="Profile" />
            ) : (
              <AccountCircleIcon style={{ fontSize: isExpanded ? '50px' : '40px', color: '#ffffff' }} />
            )}
          </ProfilePicture>
          <ProfileName expanded={isExpanded}>{profileName || 'Not Logged In'}</ProfileName>
        </ProfileContainer>
      </Sidebar>
      <MainContent>
        {user ? (
          <>
            <ContentBox />
            <ContentBox />
            <ContentBox />
            <ContentBox />
            <ContentBox />
            <ContentBox />
          </>
        ) : (
          <UnauthorizedMessage>User is not authorized to see this page</UnauthorizedMessage>
        )}
      </MainContent>
    </DashboardContainer>
  );
};

export default Dashboard;