import React, { useState, useEffect } from 'react';
import Plannr from '../assets/TitleplannrWhite.png';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AuthenticationTerms from './PlannrTerms';
import AuthenticationPrivacy from './PlannrPrivacy';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from '../firebase';
import { useNavigate } from 'react-router-dom';

const Logo = styled('div')`
  font-size: 24px;
  font-weight: bold;
  background-image: url(${Plannr});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1rem;
`;

const Authentication = () => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const isDesktop = useMediaQuery('(min-width:768px)');
  const provider = new GoogleAuthProvider();
  
  const [user, setUser] = useState(null); // State to track the current user

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set the user in state
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [auth]);

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        navigate('/dashboard');
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    console.log('Google Sign In');
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters
      style={{
        height: '100vh',
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid container style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundColor: '#586eff',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '2rem',
            height: isDesktop ? '100%' : '30%',
          }}
        >
          <Box>
            <Logo />
          </Box>
          <Box>
            <Typography variant="body1" align="left" style={{ fontStyle: 'italic' }}>
              “Plannr has transformed the way I organize and manage events, making everything effortless and more enjoyable.”
            </Typography>
            <Typography variant="body2" align="left" style={{ marginTop: '1rem' }}>
              Verdio Wong
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '2rem',
            height: isDesktop ? '100%' : '50%',
          }}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Sign In to Plannr
            </Typography>
            <Typography variant="body2" gutterBottom>
              Use your Google account to quickly sign in or register for a new account.
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              style={{ marginTop: '1rem', marginLeft: 'auto' }}
              onClick={handleGoogleSignIn}
            >
              Continue with Google
            </Button>
            <Typography variant="body2" style={{ marginTop: '2rem' }}>
              By continuing, you agree to our{' '}
              <Link onClick={() => setIsTermsOpen(true)} underline="always" style={{ cursor: 'pointer' }}>
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link onClick={() => setIsPrivacyOpen(true)} underline="always" style={{ cursor: 'pointer' }}>
                Privacy Policy
              </Link>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {isTermsOpen && <AuthenticationTerms onClose={() => setIsTermsOpen(false)} />}
      {isPrivacyOpen && <AuthenticationPrivacy onClose={() => setIsPrivacyOpen(false)} />}
    </Container>
  );
};

export default Authentication;