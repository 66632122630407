import React from 'react';
import { useTable } from 'react-table';


const billingData = [
];

/* DUMMY DATA
const billingData = [
    { date: '2024-01-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
    { date: '2024-02-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
    { date: '2024-03-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
    { date: '2024-04-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
    { date: '2024-05-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
    { date: '2024-06-15', productName: 'Plannr Pro Monthly', price: '$9.99' },
];*/

const BillingTable = () => {
  const data = React.useMemo(() => billingData, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        width: 120,
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        width: 250,
      },
      {
        Header: 'Price',
        accessor: 'price',
        width: 100,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '600px' }}>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: '#007BFF', color: 'white', textAlign: 'left' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #dddddd',
                    borderRight: '1px solid #dddddd', // Add right border to each header cell
                    fontSize: '16px',
                    width: column.width,
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #dddddd', transition: 'background-color 0.3s' }}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '12px',
                        backgroundColor: '#ffffff',
                        fontSize: '14px',
                        color: '#333333',
                        borderBottom: '1px solid #dddddd', // Add bottom border to each cell
                        borderRight: '1px solid #dddddd', // Add right border to each cell
                        borderLeft: '1px solid #dddddd', // Add left border to each cell
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BillingTable;
