import React from 'react';
import styled from 'styled-components';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000; /* Ensure this is higher than other elements */
`;

const ModalContainer = styled.div`
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 70%;
  max-width: 500px;
  max-height: 70%; /* Limit the height */
  overflow-y: auto; /* Enable scrolling */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.2s ease-out;
  z-index: 1001;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #888;
  transition: color 0.1s;

  &:hover {
    color: #333;
  }
`;

const Heading = styled.h2`
  margin-top: 0;
`;

const Text = styled.p`
  font-size: 16px;
  color: gray;
  line-height: 1.5;
`;

const PlannrPrivacy = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Heading>Plannr Account Creation Policy</Heading>
        <Text>Effective Date: 29th July 2024</Text>
        <Text>
          Welcome to Plannr! We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you create an account with us using Google authentication.
        </Text>
        <Text>
          <strong>Information We Collect</strong>
          <br />
          When you create an account with Plannr using Google authentication, we collect the following information from your Google profile:
          <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Email Address</li>
          </ul>
        </Text>
        <Text>
          <strong>How We Use Your Information</strong>
          <br />
          The information you provide will be used for the following purposes:
          <ul>
            <li>To create and manage your Plannr account.</li>
            <li>To contact you with information about your account, including security updates and notifications.</li>
            <li>To respond to any inquiries you may have regarding our service.</li>
            <li>To send you information about Plannr updates and promotions, if you opt-in to receive such communications.</li>
          </ul>
        </Text>
        <Text>
          <strong>Consent</strong>
          <br />
          By creating an account with Plannr using Google authentication, you consent to the collection and use of your personal information as described in this Privacy Policy. You also consent to receiving communications related to your account and Plannr's updates, if applicable.
        </Text>
        <Text>
          <strong>Data Protection</strong>
          <br />
          We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
        </Text>
        <Text>
          <strong>Sharing Your Information</strong>
          <br />
          We do not sell, trade, or otherwise transfer your personal information to outside parties. Your information is only accessible to authorized personnel within Plannr who need access to perform their duties.
        </Text>
        <Text>
          <strong>Your Rights</strong>
          <br />
          You have the right to:
          <ul>
            <li>Access the personal information we hold about you.</li>
            <li>Request that we correct any inaccuracies in your information.</li>
            <li>Request the deletion of your personal information.</li>
            <li>Withdraw your consent at any time by contacting us at getplannrapp@gmail.com.</li>
          </ul>
        </Text>
        <Text>
          <strong>Changes to This Privacy Policy</strong>
          <br />
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly.
        </Text>
        <Text>
          <strong>Contact Us</strong>
          <br />
          If you have any questions or concerns about this Privacy Policy, please contact us at:
          <br />
          Email: getplannrapp@gmail.com
        </Text>
        <Text>Thank you for creating an account with Plannr. We look forward to providing you with an exceptional experience!</Text>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default PlannrPrivacy;
