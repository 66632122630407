import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AccountCircleIcon from '@mui/icons-material/AccountCircleRounded';
import { useMediaQuery } from '@mui/material';
import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from '../firebase';
import { useNavigate } from 'react-router-dom';
import BillingTable from './BillingTable';

// Sidebar component
const Sidebar = styled.div`
  width: ${(props) =>
    props.isDesktop
      ? props.expanded
        ? '250px'
        : '70px'
      : props.expanded
      ? '150px'
      : '70px'};
  height: 100vh;
  background-color: #ccd4fd;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.expanded ? 'flex-start' : 'center')};
  padding-top: 20px;
  box-sizing: border-box;
  transition: width 0.3s ease;
  overflow: hidden;
`;

const SidebarItem = styled.div`
  color: #2c3e50;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  &:hover {
    background-color: #8b9bff;
  }
`;

const Icon = styled.span`
  margin-right: ${(props) => (props.expanded ? '10px' : '0')};
  margin-left: ${(props) => (props.expanded ? '0' : '20px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span`
  display: ${(props) => (props.expanded ? 'inline' : 'none')};
  vertical-align: middle;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding-left: ${(props) => (props.expanded ? '20px' : '0')};
  width: 100%;
  justify-content: ${(props) => (props.expanded ? 'flex-start' : 'center')};
`;

const ProfilePicture = styled.div`
  width: ${(props) => (props.expanded ? '50px' : '40px')};
  height: ${(props) => (props.expanded ? '50px' : '40px')};
  border-radius: 50%;
  background-color: #34495e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.expanded ? '10px' : '0')};

  &:hover {
    width: ${(props) => (props.expanded ? '50px' : '40px')};
    height: ${(props) => (props.expanded ? '50px' : '40px')};
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: none;
    &:hover {
      transform: none;
    }
  }
`;

const ProfileName = styled.span`
  display: ${(props) => (props.expanded ? 'inline' : 'none')};
`;

// Main content area
const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${props => props.active ? '2px solid #007bff' : 'none'};
  color: ${props => props.active ? '#007bff' : '#333'};
  transition: color 0.3s, border-bottom 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const UpgradeSection = styled.div`
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

const StudentDiscountLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const InfoIconStyled = styled(InfoIcon)`
  margin-left: 5px;
  color: #007bff;
  cursor: pointer;
`;

const BenefitsContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  max-width: 500px; /* Set a maximum width */
  width: 100%; /* Ensures the container is responsive */
`;

const BenefitsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const BenefitItem = styled.li`
  margin-bottom:  5px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    margin-right:   0px;
    display: flex;
    align-items: center;
  }
`;  

const UpgradeButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-start;
  &:hover {
    background-color: #0056b3;
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
`;

const BillingSection = styled.div`
  margin-top: 20px;
`;

const BillingTitle = styled.h2`
  margin-bottom: 10px;
`;

const BillingMessage = styled.p`
  color: #555;
`;

const FeaturesTitle = styled.h3`
  font-size: 20px; /* Adjust the font size as needed */
  color: #007bff; /* Primary color */
  margin-bottom: 10px; /* Space below the heading */
  margin-top: 5px;
  font-weight: bold; /* Make the font bold */
`;

const Settings = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const [user, setUser] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');

  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setPhotoURL(user.photoURL);
        setProfileName(user.displayName);
      } else {
        setPhotoURL(null);
        setProfileName('Not Signed In');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User logged out');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/settings');
      }).catch((error) => {
        console.error('Error logging in:', error);
      });
  };

  return (
    <SettingsContainer>
      <Sidebar
        isDesktop={isDesktop}
        expanded={isExpanded}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <SidebarItem onClick={() => navigate('/dashboard')}>
          <Icon expanded={isExpanded}>
            <DashboardIcon />
          </Icon>
          <Label expanded={isExpanded}>Dashboard</Label>
        </SidebarItem>
        <SidebarItem onClick={() => navigate('/profile')}>
          <Icon expanded={isExpanded}>
            <PersonIcon />
          </Icon>
          <Label expanded={isExpanded}>Profile</Label>
        </SidebarItem>
        <SidebarItem onClick={() => navigate('/dashboard/plans')}>
          <Icon expanded={isExpanded}>
            <SettingsIcon />
          </Icon>
          <Label expanded={isExpanded}>Plannr Pro</Label>
        </SidebarItem>

        {user ? (
          <SidebarItem onClick={handleLogout}>
            <Icon expanded={isExpanded}>
              <LogoutIcon />
            </Icon>
            <Label expanded={isExpanded}>Sign Out</Label>
          </SidebarItem>
        ) : (
          <SidebarItem onClick={handleGoogleSignIn}>
            <Icon expanded={isExpanded}>
              <LoginIcon />
            </Icon>
            <Label expanded={isExpanded}>Sign In</Label>
          </SidebarItem>
        )}

        <ProfileContainer expanded={isExpanded}>
          <ProfilePicture expanded={isExpanded}>
            {photoURL ? (
              <img src={photoURL} alt="Profile" />
            ) : (
              <AccountCircleIcon style={{ fontSize: isExpanded ? '50px' : '40px', color: '#ffffff' }} />
            )}
          </ProfilePicture>
          <ProfileName expanded={isExpanded}>{profileName || 'Not Signed In'}</ProfileName>
        </ProfileContainer>
      </Sidebar>
      <MainContent>
        <Title>
          <SettingsIcon style={{ marginRight: '10px' }} />
          Billing
        </Title>

        <TabContainer>
          <Tab active={activeTab === 'overview'} onClick={() => setActiveTab('overview')}>
            Overview
          </Tab>
          <Tab active={activeTab === 'billing'} onClick={() => setActiveTab('billing')}>
            Billing history
          </Tab>
        </TabContainer>

        {activeTab === 'overview' && (
          <>
            <h2>Upgrade to Plannr Pro</h2>
            <UpgradeSection>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>Need a student discount?</p>
                <Tooltip 
                  title={<span style={{ fontSize: '0.8rem' }}>Please email us with your educational institution's details and we'll provide you with a discount code.</span>} 
                  arrow 
                  sx={{ fontSize: '1.2rem' }}
                  >
                  <InfoIconStyled />
                </Tooltip>
              </div>
            </UpgradeSection>

            <BenefitsList>
              <FeaturesTitle>Plannr Pro Features:</FeaturesTitle>
              <BenefitsContainer>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Bigger group size (up to 50 friends)
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Unlimited hangout events
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Host events with no advance time restrictions
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Priority AI processing for faster scheduling
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Integration with calendar apps
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Customizable notifications and reminders
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                Dedicated customer support
              </BenefitItem>
              <BenefitItem>
                <ChevronRightIcon style={{ color: '#007bff' }} />
                No Ads
              </BenefitItem>
              </BenefitsContainer>
            </BenefitsList>

            <UpgradeButton>Upgrade Now</UpgradeButton>
          </>
        )}

        {activeTab === 'billing' && (
          <BillingSection>
            <BillingTitle>Showing invoices within the past 6 months</BillingTitle>
            <BillingTable/>
          </BillingSection>
        )}
      </MainContent>
    </SettingsContainer>
  );
};

export default Settings;
