import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Plannr from '../assets/Titleplannr.png';
import { signOut } from "firebase/auth";
import auth from '../firebase';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  background-image: url(${Plannr});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
`;

const RightNav = styled.div`
  display: flex;
  gap: 20px;
`;

const HomeButton = styled.button`
  background-color: #ffffff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    color: #6649bd;
  }
`;

const LoginButton = styled.button`
  background-color: #8860ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #6649bd;
  }
`;

const SignUpButton = styled.button`
  background-color: #8860ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #6649bd;
  }
`;

const Header = () => {
  const navigate = useNavigate(); // Hook for navigation
  const [user, setUser] = useState(null); // State to track the current user

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set the user in state
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [auth]);
  
  const handleHomeClick = () => {
    navigate('/');
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User logged out');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <HeaderContainer>
      <Logo />
      <RightNav>
        <HomeButton onClick={handleHomeClick}>Home</HomeButton>
        {user ? (
          <>
            <HomeButton onClick={handleDashboardClick}>Dashboard</HomeButton>
            <LoginButton onClick={handleLogout}>Logout</LoginButton>
          </>
        ) : (
          <LoginButton onClick={handleLoginClick}>Login</LoginButton>
        )}
      </RightNav>
    </HeaderContainer>
  );
};

export default Header;
