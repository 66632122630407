import React from 'react';
import styled from 'styled-components';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000; /* Ensure this is higher than other elements */
`;

const ModalContainer = styled.div`
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 70%;
  max-width: 500px;
  max-height: 70%; /* Limit the height */
  overflow-y: auto; /* Enable scrolling */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.2s ease-out;
  z-index: 1001;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #888;
  transition: color 0.1s;

  &:hover {
    color: #333;
  }
`;

const Heading = styled.h2`
  margin-top: 0;
`;

const Text = styled.p`
  font-size: 16px;
  color: gray;
  line-height: 1.5;
`;

const PlannrTerms = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Heading>Plannr Terms of Service</Heading>
        <Text>Effective Date: 29th July 2024</Text>
        <Text>
          Welcome to Plannr! These Terms of Service ("Terms") govern your access to and use of Plannr. By creating an account using Google authentication, you agree to comply with and be bound by these Terms. Please read them carefully.
        </Text>
        <Text>
          <strong>1. Acceptance of Terms</strong>
          <br />
          By creating an account with Plannr using Google authentication, you accept and agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our service.
        </Text>
        <Text>
          <strong>2. Account Creation</strong>
          <br />
          To create an account with Plannr, you must use Google authentication. You agree to provide accurate and complete information from your Google profile, and to keep your information updated as necessary.
        </Text>
        <Text>
          <strong>3. Use of Information</strong>
          <br />
          By creating an account, you consent to Plannr using your information for the following purposes:
          <ul>
            <li>To create and manage your Plannr account.</li>
            <li>To communicate with you regarding your account and service updates.</li>
            <li>To respond to any inquiries or feedback you may have.</li>
            <li>To send you information about Plannr updates and promotions, if you opt-in to receive such communications.</li>
          </ul>
        </Text>
        <Text>
          <strong>4. Data Protection</strong>
          <br />
          We are committed to protecting your personal information. Please refer to our Privacy Policy for details on how we collect, use, and protect your data.
        </Text>
        <Text>
          <strong>5. Early Access Disclaimer</strong>
          <br />
          As an early access user, you may encounter bugs or issues while using Plannr. We are continuously working to improve our app, and your feedback is valuable to us. However, Plannr is not liable for any issues, damages, or losses that may arise from using the app during this early access phase.
        </Text>
        <Text>
          <strong>6. Changes to Terms</strong>
          <br />
          We may update these Terms from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. Your continued use of Plannr constitutes your acceptance of the updated Terms.
        </Text>
        <Text>
          <strong>7. Termination</strong>
          <br />
          We reserve the right to suspend or terminate your access to Plannr at our discretion, without prior notice, if we believe you have violated these Terms.
        </Text>
        <Text>
          <strong>8. Contact Us</strong>
          <br />
          If you have any questions or concerns about these Terms, please contact us at:
          <br />
          Email: getplannrapp@gmail.com
        </Text>
        <Text>Thank you for using Plannr. We look forward to providing you with a great experience!</Text>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default PlannrTerms;
