import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Pricing from './components/Pricing';
import Background from './components/Background';
import FAQ from './components/FAQ';
import Features from './components/Features';
import PricingMobile from './components/PricingMobile';
import useMediaQuery from './hooks/useMediaQuery'; // Adjust the path as necessary
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Authentication from './components/Authentication';
import Dashboard from './components/Dashboard';
import Settings from './components/Billings';
import NotFound from './components/NotFound'; // Import your 404 component

// Define your theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Define primary color
    },
    secondary: {
      main: '#dc004e', // Define secondary color
    },
    // Add more customization if needed
  },
});

const App = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <ThemeProvider theme={theme}> {}
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <Home />
              <Background />
              <Features />
              {isMobile ? <PricingMobile /> : <Pricing />}
              <FAQ />
              <Footer />
            </>
          } />
          <Route path="/login" element={<Authentication />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/plans" element={<Settings />} />
          <Route path="*" element={<NotFound />} /> {/* 404 Route */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
